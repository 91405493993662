import { Box, Container, Group, Stack, Text, Title } from "@mantine/core";
import Image from "next/image";
import { useEffect, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useAppStore } from "../common/stores/app.store";
import PageTitle from "../components/common/PageTitle";
import { Carousel } from "@mantine/carousel";
import { PLAY_STORE_LINK } from "../common/util";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { i18Prefixes } from "../common/i18";
const iosWidth = 828;
const iosHeight = 1792;
const scale = 0.25;
const iosScaledWidth = iosWidth * scale;
const iosScaledHeight = iosHeight * scale;
const IOSCarousel = () => {
  const { t } = useTranslation(["app"]);
  return (
    <Carousel slideSize="70%" mx="auto" height={iosScaledHeight} slideGap="md">
      <Carousel.Slide>
        <Box ta="center">
          <Image
            src="/assets/images/install-ios1.png"
            width={iosScaledWidth}
            height={iosScaledHeight}
            alt={t("iosInstallInstructions1")}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Carousel.Slide>
      <Carousel.Slide>
        <Box ta="center">
          <Image
            src="/assets/images/install-ios2.png"
            width={iosScaledWidth}
            height={iosScaledHeight}
            alt={t("iosInstallInstructions2")}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Carousel.Slide>
      <Carousel.Slide>
        <Box ta="center">
          <Image
            src="/assets/images/install-ios3.png"
            width={iosScaledWidth}
            height={iosScaledHeight}
            alt={t("iosInstallInstructions3")}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Carousel.Slide>
    </Carousel>
  );
};
const PlayLink = () => (
  <Group justify="center">
    {/* eslint-disable-next-line react/jsx-no-target-blank */}
    <a href={PLAY_STORE_LINK} target="_blank">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        width={200}
        height={77}
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      />
    </a>
  </Group>
);
const App = () => {
  const isPwa = useAppStore((s) => s.isPwa);
  const { t } = useTranslation(["common", "app"]);
  const title = `${t("scoreboarder")} | ${t("app")} `;
  const description = t("description", i18Prefixes.app);
  const [state, setState] = useState({
    ios: false,
    android: false,
    other: true,
  });
  useEffect(() => {
    const ios = isMobile && isIOS;
    // console.log("ios :", ios);
    const android = isMobile && !isIOS;
    // console.log("android :", android);
    // console.log("isPwa :", isPwa);
    const other = !ios && !android && !isPwa;
    // console.log("other :", other);
    setState({ ios, android, other });
  }, [isPwa]);
  return (
    <Container>
      <PageTitle title={title} description={description} />
      <Stack pt={20}>
        {state.ios && (
          <Stack>
            <Title order={3}>{t("titleIos", i18Prefixes.app)}</Title>
            <Text mb={20}>{t("bodyIos", i18Prefixes.app)}</Text>
            <IOSCarousel />
          </Stack>
        )}
        {state.android && (
          <Stack>
            <Title order={3}>{t("titleAndroid", i18Prefixes.app)}</Title>
            <Text mb={20}>{t("bodyAndroid", i18Prefixes.app)}</Text>
            <PlayLink />
          </Stack>
        )}
        {state.other && (
          <Stack>
            <Title order={3}>{t("title", i18Prefixes.app)}</Title>
            <PlayLink />
            <Text mb={20}>{t("body", i18Prefixes.app)}</Text>
            <IOSCarousel />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};
export default App;

interface StaticProps {
  locale: string;
}
export async function getStaticProps({ locale }: StaticProps) {
  return {
    revalidate: 60,
    props: {
      ...(await serverSideTranslations(locale, ["common", "app"])),
    },
  };
}
